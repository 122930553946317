import axios from 'axios';
import {IP_STACK_TOKEN} from '../../config'
export const getUserIP = async () => {
    try {
      const { data } = await axios.get('https://api.ipify.org?format=json');
      return data;
    } catch (error) {
      return false;
    }
  };
export const getUserLocationUsingIP = async ({ IP }: { IP: string }) => {
    try {
      const { data } = await axios.get(`https://api.ipstack.com/${IP}?access_key=${IP_STACK_TOKEN}`);
      return data;
    } catch (error) {
      return false;
    }
  };