import Header from './sections/headerSection'
import './App.css';
import './index.css'

function App() {
  return (
    <div>
     <Header/>
    </div>
  );
}

export default App;
