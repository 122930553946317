//@ts-ignore
import axios from 'axios'
export const signinRequest = async (
  email: string,
  password: string,
) => {
  try {
    const response = await axios.post('https://api.getclinio.com/api/Users', {
      email,
      password,
    });
    return response;
  } catch (error) {
  }
};

export const verifyRequest = async (
    code: string,
    email: string
  ) => {
    try {
      const response = await axios.post('https://api.getclinio.com/api/Users/activateEmail', {
        code,
        email
      });
      return response?.data;
    } catch (error) {
    }
  };

  
export const registerRequestinfo = async (
    name: string,
    mobile:string,
    email: string
  ) => {
    try {
      const response = await axios.post('https://api.getclinio.com/api/Users/sendCode', {
        name,
        mobile,
        email
      });
      return response?.data;
    } catch (error) {
    }
  };

    
export const verifyOtpRequest = async (
    code:string,
    email: string
  ) => {
    try {
      const response = await axios.post('https://api.getclinio.com/api/Users/verifyPhone', {
        code,
        email
      });
      return response?.data;
    } catch (error) {
    }
  };
  
  export const resendEmailCode = async (
    email: string
  ) => {
    try {
      const response = await axios.post('https://api.getclinio.com/api/Users/resendEmail', {
        email
      });
      return response?.data;
    } catch (error) {
    }
  };
  