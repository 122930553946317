import React from "react";
import {
  signinRequest,
  verifyRequest,
  registerRequestinfo,
  verifyOtpRequest,
  resendEmailCode,
} from "../apis/registerApi";
import { validateEmail } from "../../../utilis/validators";
const useSigninRequest = () => {
  const [locationIp, setLocation] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState(1);
  const [resendTimer, setResendTimer] = React.useState(1);
  const [trialsResendCode, setTrialsResendCode] = React.useState(0);
  const [steps, setStepsProgress] = React.useState([
    { name: "Step 1", href: "#", status: "current" },
    { name: "Step 2", href: "#", status: "upcoming" },
    { name: "Step 3", href: "#", status: "upcoming" },
    { name: "Step 4", href: "#", status: "upcoming" },
    { name: "Step 5", href: "#", status: "upcoming" },
  ]);
  
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    phoneCode: "",
    countryCode: "",
    emailCode: "",
    name: "",
  });
  const handleLocationGetted = React.useCallback(() => {
    let x = "";
    if (locationIp === "Egypt ") x= "+20";
    if (locationIp === "UAE") x= "+971";
    if (locationIp === "KSA") x= "+966";
    if (locationIp === "Australia") x= "+61";
    if (locationIp === "USA") x= "+1";
    else x= "+20"
    setState((oldState) => ({
      ...oldState,
      countryCode: x,
    }));
  },[locationIp,setState]);
  React.useEffect(()=>{
    handleLocationGetted();
  },[handleLocationGetted])
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingResend, setLoadingResend] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const handleChange = React.useCallback(
    (name: string) =>
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        setState((oldState) => ({
          ...oldState,
          [name]: value,
        }));
      },
    [setState, state, setError]
  );

  const handleSubmit = React.useCallback(async () => {
    const isValidEmail = validateEmail(state.email);
    setLoading(true);
    if (!isValidEmail) {
      setLoading(false);
      setError("Email is not correct");
    }
    if (
      state.email === "" ||
      state.password === "" ||
      state.confirmPassword === ""
    ) {
      setLoading(false);
      return setError("This field is required");
    }
    if (state.password !== state.confirmPassword) {
      setLoading(false);
      return setError("Passwords Not Matched");
    }

    //  const isValidEmail = validateEmail(state.email);
    // if (!isValidEmail) {
    //  }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await signinRequest(state.email, state.password);
    if (response?.data?.statusCode === 1) {
      let tempSteps = steps;
      tempSteps[0].status = "complete";
      tempSteps[1].status = "current";
      setStepsProgress(tempSteps);
      setCurrentStep(2);
      setLoading(false);
    } else if (response?.data.statusCode === 10) {
      setLoading(false);
      setError("This email already exists.");
    }
  }, [setLoading, state, setError, steps]);

  const verifyEmail = React.useCallback(async () => {
    if (state.emailCode === "") return setError("This Field is required");
    setLoading(true);

    const response = await verifyRequest(state.emailCode, state.email);
    if (response?.statusCode === 2) {
      let tempSteps = steps;
      tempSteps[1].status = "complete";
      tempSteps[2].status = "current";
      setStepsProgress(tempSteps);
      setCurrentStep(3);
      setLoading(false);
    } else {
      setLoading(false);
      setError("Code doesn't match");
    }
  }, [setLoading, state, setError, steps]);

  const registerInfo = React.useCallback(
    async (resend: boolean) => {
      if (resend) setLoadingResend(true);
      else setLoading(true);
      let phone = state.countryCode + state.mobile + "";
      if (state.name === "") {
        setError("Name Field is required");
      }
      if (state.mobile === "") {
        setError("Mobile Field is required");
      }
      // setError("Error Mail");
      //  }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await registerRequestinfo(
        state.name,
        phone,
        state.email
      );
      if (response?.statusCode === 3) {
        let tempSteps = steps;
        tempSteps[2].status = "complete";
        tempSteps[3].status = "current";
        setStepsProgress(tempSteps);
        setCurrentStep(4);
        setLoading(false);
        setLoadingResend(false);
      } else if (response?.statusCode === 14) {
        setLoading(false);
        setLoadingResend(false);
        setError("This phone is already associated with account");
      }
    },
    [setLoading, state, steps, setLoadingResend]
  );
  const verifyOtp = React.useCallback(async () => {
    setLoading(true);
    // setError("Error Mail");
    //  }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await verifyOtpRequest(state.phoneCode, state.email);
    if (response?.statusCode === 4) {
      let tempSteps = steps;
      tempSteps[3].status = "complete";
      tempSteps[4].status = "current";
      setStepsProgress(tempSteps);
      setCurrentStep(5);
      setLoading(false);
    } else if (response?.statusCode === 16) {
      setLoading(false);
      setError(response?.message);
    }
  }, [setLoading, state, steps]);

  const resendEmail = React.useCallback(async () => {
    setLoadingResend(true);
    // setError("Error Mail");
    //  }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await resendEmailCode(state.email);
    if (response?.statusCode === 1) {
      setLoadingResend(false);
    } else if (response?.statusCode === 16) {
      setLoadingResend(false);
      setError(response?.message);
    }
  }, [setLoadingResend, state.email]);

  return {
    state,
    loadingResend,
    handleChange,
    handleSubmit,
    verifyEmail,
    loading,
    error,
    currentStep,
    setCurrentStep,
    setStepsProgress,
    steps,
    registerInfo,
    verifyOtp,
    resendTimer,
    setResendTimer,
    trialsResendCode,
    setTrialsResendCode,
    resendEmail,
    setLocation,
    locationIp,
  };
};

export default useSigninRequest;
